<template>
    <div class="card border-0 overflow-hidden h-100 playfairDisplay" @mouseover="hover = true" @mouseleave="hover = false">
        <div :class="{'opacity-05':mesaDF.CANTIDAD>0 && mesaDF.CANTIDAD == mesaDF.CANTIDAD_COMPRADA}">
            <div class="card-img-top position-relative" style="height: 300px;">
                <div v-if="producto.adjuntos && producto.adjuntos.length>0" class="h-100 w-100">
                    <div class="position-absolute" style="top: 5px; right: 5px;" v-if="mesaDF.CANTIDAD>0">
                        <span class="badge bg-primary">{{ mesaDF.CANTIDAD_COMPRADA }} / {{ mesaDF.CANTIDAD }}</span>
                    </div>
                    <img :src="producto.adjuntos[0].ARCHIVO" :alt="producto.NOMBRE_PRODUCTO" class="img-background h-100 w-100">
                </div>
                <div class="d-flex align-items-center justify-content-center h-100 bg-light" v-else>
                    <font-awesome-icon :icon="faFileImage" style="height: 80px; width: 80px;" class="text-muted" />
                </div>
                <div class="position-absolute bottom-0 start-0 end-0" v-show="hover || mobile">
                    <button type="button" class="w-100 btn rounded-0 py-3 bg-light-secondary" v-on:click.prevent.stop="addProduct()" :disabled="loading" v-if="tipo=='producto'">
                        <BtnLoading v-if="loading" text="Agregando..." />
                        <span v-else>
                            <font-awesome-icon :icon="faGifts" /> Agregar a mi mesa
                        </span>
                    </button>
                    <button type="button" class="w-100 btn rounded-0 py-3 bg-light-secondary" v-on:click.prevent.stop="deleteMesas()" :disabled="loading" v-if="tipo=='mesa'">
                        <BtnLoading v-if="loading" text="Eliminando..." />
                        <span v-else>
                            <font-awesome-icon :icon="faTrash" /> Eliminar de la mesa
                        </span>
                    </button>
                    <button type="button" class="w-100 btn rounded-0 py-3 bg-light-secondary" v-on:click.prevent.stop="addShop()" :disabled="loading" v-if="tipo=='compra' && mesaDF.CANTIDAD != mesaDF.CANTIDAD_COMPRADA">
                        <BtnLoading v-if="loading" text="Añadiendo..." />
                        <span v-else>
                            <font-awesome-icon :icon="faShoppingCart" /> Añadir al carrito
                        </span>
                    </button>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="playfairDisplay text-dark">
                    <p class="card-title mb-0 text-start float-start">
                        {{producto.NOMBRE_PRODUCTO}} - {{producto.DESCRIPCION_MARCA}}
                    </p>
                    <span class="text-end float-end">{{$filters.currency(producto.PRECIO)}}</span>
                </div>
            </div>
        </div>
        <div v-if="mesaDF.CANTIDAD>0 && mesaDF.CANTIDAD == mesaDF.CANTIDAD_COMPRADA" class="text-danger fs-1 position-absolute" style="top: 0px; left: 0px; right: 0px; bottom: 0px;">
            <div class="d-flex h-100 align-items-center justify-content-center" style="transform: rotate(320deg);">
                Vendido
            </div>
        </div>
    </div>
</template>
<script>
import BtnLoading from '@/components/BtnLoading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGifts, faFileImage, faTrash, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
export default {
    name: 'card-producto',
    props: ['data', 'id', 'type', 'mesa'],
    components: {
        BtnLoading,
        FontAwesomeIcon
    },
    data(){
        return {
            loading: false,
            faGifts,
            faFileImage,
            faTrash,
            faShoppingCart,
            hover: false,
        }
    },
    methods: {
        addProduct(){
            this.loading = true
            this.$store.dispatch('addMesas', {
                CODIGO_PRODUCTO: this.producto.CODIGO_PRODUCTO,
                CANTIDAD: 1
            }).then(res => {
                this.$toast.success('El producto ha sido añadido')
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error('Error al agregar producto')
                }
            }).then(() => {
                this.loading = false
            })
        },
        addShop(){
            this.loading = true
            var data = this.mesa || {}
            data.CANTIDAD_ITEMS = 1
            //data.CODIGO_BODA = this.boda
            if(data.CODIGO_BODA){
                this.$store.commit('addItem', data)
                this.$toast.success('El producto ha sido agregado')
            }else{
                this.$toast.error('No se ha seleccionado la boda')
            }
            this.loading = false
        },
        deleteMesas(){
            this.loading = true
            this.$store.dispatch('deleteMesas', {
                id: this.mesa.CODIGO_BODA_MESA //id
            }).then(res => {
                this.$toast.success('El producto ha sido eliminado')
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error('Error al eliminar producto')
                }
            }).then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        producto(){
            return this.data || {}
        },
        mobile(){
            return this.$store.getters.mobile
        },
        tipo(){
            return this.type || 'producto'
        },
        mesaDF(){
            return this.mesa || {}
        }
    }
}
</script>
<template>
    <div class="wrapper">
        <div class="position-fixed start-0 end-0 top-0 vh-35">
            <img :src="ImgPortada" alt="Encuentra una mesa de regalo" class="img-background h-250 w-400">
            <div class="position-absolute top-0 bottom-0 start-0 end-0">
                <div class="d-flex align-items-center"></div>
            </div>
        </div>
        
        <div class="container pt-body">
            <div class="card border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <div class="card bg-light border-0 shadow">
                                <div class="card-body">
                                    <h2>Filtros</h2>
                                    <div class="row">
                                        <div class="col-lg-12">
                                        </div>
                                        <div class="col-lg-12 mb-3">
                                            <label for="proveedor">Proveedor:</label>
                                            <Proveedores type="text" name="proveedor" id="proveedor" v-model="filters.CODIGO_PROVEEDOR" :disabled="loading"/>
                                        </div>

                                        <div class="col-lg-12 mb-3">
                                            <label for="grupo">Grupo:</label>
                                            <ProductoGrupo type="text" name="grupo" id="grupo" v-model="filters.CODIGO_PRODUCTO_GRUPO" :disabled="loading"/>
                                        </div>

                                        <div class="col-lg-12 mb-3">
                                            <label for="tipo">Tipo:</label>
                                            <ProductoTipo type="text" name="tipo" id="tipo" v-model="filters.CODIGO_PRODUCTO_TIPO" :disabled="loading"/>
                                        </div>

                                        <div class="col-lg-12 mb-3">
                                            <label for="marca">Marca:</label>
                                            <ProductoMarca type="text" name="marca" id="marca" v-model="filters.CODIGO_MARCA" :disabled="loading"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9 mb-3">
                            <img :src="ImgPortada2" alt="" class="img-fluid mb-3">

                            <div class="position-absolute top-0 end-0 mt-5 me-2">
                                <router-link :to="{ name: 'boda.index' }" class="btn btn-primary me-2">
                                    <font-awesome-icon :icon="faArrowLeft" />
                                    Volver a la mesa
                                </router-link>
                            </div>
                            

                            <div class="alert alert-warning d-flex align-items-center mb-3" role="alert" v-if="!boda.DIRECCION_ENVIO && user.CODIGO_BODA == boda.CODIGO_BODA">
                                <svg class="bi flex-shrink-0 me-2" width="24" height="24"><use xlink:href="#exclamation-triangle-fill"/></svg>
                                <div>
                                    Debes completar tu dirección para la entrega de tus regalos.
                                </div>
                            </div>
                            
                            <div class="row justify-content-end" v-if="false">
                                <div class="col-auto mb-3">
                                    <div class="d-flex align-items-center">
                                        <label class="form-label me-1 flex-shrink-0" for="mostrar_por">Mostrar por</label>
                                        <select name="mostrar_por" id="mostrar_por" class="form-select" v-model="filters.mostrar_por">
                                            <option value="" disabled selected>
                                                Seleccionar
                                            </option>
                                            <option value="0">Posición</option>
                                            <option value="1">Precio</option>
                                            <option value="3">Nombre</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(loadingProductos) || productosFiltered.length<=0">
                                <div v-if="(loadingProductos)" class="text-muted text-center py-5">
                                    <div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <h2>
                                        Cargando...
                                    </h2>
                                </div>
                                <div v-else class="py-3">
                                    <div class="text-center mx-auto bg-light rounded-circle p-5" style="width: 200px; height: 200px;">
                                        <img :src="SVGEmpty" class="img-fluid">
                                    </div>
                                    <div class="text-center">
                                        <p>
                                            No se encontraron registros
                                        </p>
                                        <router-link :to="{name: 'admin.create'}" class="btn btn-primary">
                                            <font-awesome-icon :icon="faPlus" /> Agregar producto
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="row align-items-stretch" v-if="productosFiltered.length>0">
                                    <div class="col-lg-4 mb-3" v-for="(producto, index) in  productosFiltered" :key="index">
                                        <router-link :to="{name: 'productos.show', params: {id: producto.SLUG}}">
                                            <cardProducto :data="producto"></cardProducto>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CardProducto from '@/components/productos/CardProducto.vue'
import ImgPortada from '@/assets/img/mesa.jpg'
import ImgPortada2 from '@/assets/img/rosehouse.jpg'


import Proveedores from '@/components/catalogos/Proveedores.vue'
import ProductoGrupo from '@/components/catalogos/ProductoGrupo.vue'
import ProductoTipo from '@/components/catalogos/ProductoTipo.vue'
import ProductoMarca from '@/components/catalogos/ProductoMarca.vue'


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default {
    components: {
        CardProducto,
        Proveedores,
        ProductoGrupo,
        ProductoTipo,
        ProductoMarca,
        FontAwesomeIcon,
    },
    data(){
        return {
            faArrowLeft,
            filters: {
                mostrar_por: 0,
                CODIGO_PROVEEDOR: '',
                CODIGO_PRODUCTO_GRUPO: '',
                CODIGO_PRODUCTO_TIPO: '',
                CODIGO_MARCA: '',
                CODIGO_CELEBRACION: ''
            },
            ImgPortada,
            ImgPortada2,
          
            loading: false,
            loadingProductos: false
        }
    },
    mounted(){
        this.getProductos()
        this.getBoda()
        this.filters.CODIGO_CELEBRACION = this.boda.CODIGO_CELEBRACION
    },
    methods: {
        getProductos(){
            this.loadingProductos = true
            this.$store.dispatch('getProductos', this.filters).then((res) => {
                return res
            }).catch(err => {
                //this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingProductos = false
            })
        },

        getBoda(){
            /*if(!this.codigo){
                this.$router.push({name: 'mesas.create'})
                return
            }*/
            //this.loadingMesa = true
            this.$store.dispatch('getBoda', {
                codigo: this.codigo
            }).then((res) => {
                return res
            }).catch(err => {
                //this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                //this.loadingMesa = false
            })
        },

    },
    computed:{
        codigo(){
            return this.user.CODIGO_BODA || -1
        },

        boda() {
            return this.$store.getters.boda || {}
        },
        user() {
            return this.$store.getters.currentUser || {}
        },
        productos() {
            return this.$store.getters.productos || {}
        },
        productosFiltered() {
            return this.productos.data || []
        },
        query(){
            return this.$route.query || {}
        },
        /*queries(){
            var qr = {}
            if (this.query.proveedor) {
                qr['CODIGO_PROVEEDOR'] = this.query.proveedor
            }

            if (this.query.tipo) {
                qr['CODIGO_PRODUCTO_TIPO'] = this.query.tipo
            }
            
            return qr
        }*/
    },
    watch: {
        user(val){
            if(val.CODIGO_USUARIO){
                this.getBoda()
            }
        },
        query: {
            handler(val){
                this.filters.CODIGO_PROVEEDOR = (val.CODIGO_PROVEEDOR || val.proveedor) || ''
                this.filters.CODIGO_PRODUCTO_GRUPO = val.CODIGO_PRODUCTO_GRUPO || ''
                this.filters.CODIGO_PRODUCTO_TIPO = (val.CODIGO_PRODUCTO_TIPO || val.tipo) || ''
                this.filters.CODIGO_MARCA = val.CODIGO_MARCA || ''
                this.filters.CODIGO_CELEBRACION = this.boda.CODIGO_CELEBRACION || ''
            },
            immediate: true
        },
        'filters': {
            handler(val){
                this.getProductos()
                return val
            },
            deep: true
        }
    }
}
</script>